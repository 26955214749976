<template>
	<div class="payment">
		<div id="zfbPaymentLayer" v-if="pay_type == 'alipay'">
			<div class="main">
				<div class="h3">
					<div class="left">订单提交成功，请尽快支付！订单号：<span class="order" id="zfborder">{{orderno}}</span></div>
					<div class="right">应付：<span class="Price" id="zfbPrice">￥{{totalPrice}}</span></div>
				</div>
				<div class="sweepCode">
					<div class="codeBox">
						<div class="QRCode">
							<div class="title">支付宝扫码支付</div>
							<div class="expiredReminder">距离二维码过期还有<span class="Seconds" id="zfbSeconds">{{Seconds}}</span>秒,过期后请点击刷新重新获取二维码</div>
							<div class="codeDiv" v-loading="loading" 
								element-loading-text="拼命加载中"
								element-loading-spinner="el-icon-loading">
								<div class="codeImgBxo">
									<div class="codeImg" id="zfbcodeImg" ref="codeImg">
										<!--<img src="../banner/test.png" />-->
									</div>
									<div v-if="error" class="error" id="zfberror">二维码已过期</div>
								</div>
								<div class="refurbish" @click="refurbishEvent" v-if="error">
									<i class="el-icon-refresh-left"></i>
									<span>点击刷新</span>
								</div>
							</div>
						</div>
						<div class="handset" id="zfbhandset">
					
						</div>
					</div>
				</div>
			</div>	
		</div>
		<div id="wxPaymentLayer" v-else>
			<div id="PaymentLayer"></div>
			<div class="main">
				<div class="h3">
					<div class="left">订单提交成功，请尽快支付！订单号：<span class="order" id="order">{{orderno}}</span></div>
					<div class="right">应付：<span class="Price" id="Price">￥{{totalPrice}}</span></div>
				</div>
				<div class="sweepCode">
					<div class="codeBox">
						<div class="QRCode">
							<div class="title">微信扫码支付</div>
							<div class="expiredReminder">距离二维码过期还有<span class="Seconds" id="Seconds">{{Seconds}}</span>秒,过期后请点击刷新重新获取二维码</div>
							<div class="codeDiv" v-loading="loading" 
								element-loading-text="拼命加载中"
								element-loading-spinner="el-icon-loading">
								<div class="codeImgBxo">
									<div class="codeImg" id="codeImg" ref="codeImg">
										<!--<img src="../banner/test.png" />-->
									</div>
									<div v-if="error" class="error" id="error">二维码已过期</div>
								</div>
								<div class="refurbish" id="refurbish" @click="refurbishEvent" v-if="error">
									<i class="el-icon-refresh-left"></i>
									<span>点击刷新</span>
								</div>
							</div>
						</div>
						<div class="handset" id="handset">
					
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js'; 
	import QRCode from 'qrcodejs2'
	export default {
	    data() {
			return {
				pay_type:"",//支付方式
				totalPrice:"",//应付
				orderno:"",//订单号
				itime:120,//倒计时
				Seconds:"",//显示倒计时
				error:false,//显示已过期
				tFun:null,//倒计时方法
				loading:true,
			}
		},
		methods:{
			//生成二维码
			getSubmitOrder(){
				if(this.PublicJs.Decrypt(this.$route.query.pay_type) == "alipay"){
					var that = this;
					this.$http.post(this.PublicJs.publicPath + "/api/student.Order/submitOrder.html", {
						token:window.sessionStorage.getItem('token'),
						pay_type:this.PublicJs.Decrypt(this.$route.query.pay_type),
						ids:this.$route.query.order_id,
						method:"scan",
						is_pay:1
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == "1"){
							this.orderno = response.data.data.order_no;
							this.creatQrCode(response.data.data.expend.qrcode_url);
							this.itime = 120;
							//倒计时
							this.countDown();
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
							setTimeout(function(){
								that.$router.push({
									path: "/courseList"
								});
							},2000);
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				}else{
					var that = this;
					this.$nextTick(function(){
						//查询订单详情
						that.getOrderDetail()
						var QRCodeUrl = window.location.origin + "/mobile/pay?id=" + this.PublicJs.Decrypt(that.$route.query.order_id)+ "&pc=" + window.sessionStorage.getItem('token');
						console.log(QRCodeUrl)
						that.creatQrCode(QRCodeUrl);
						that.itime = 120;
						//倒计时
						that.countDown();
					})
				}
				
				
			},
			//生成支付二维码
			creatQrCode(text) {
				var qrcode = new QRCode(this.$refs.codeImg, {
					text: text,
					width: 235,
					height: 235,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
				this.loading = false;
			},
			//倒计时
			countDown() {
				var that = this;
				this.itime = this.itime - 1;
				this.Seconds = this.itime;
				if(this.itime == 0) {
					this.Seconds = 0;
					this.itime = 120;
					this.error = true;
					return;
				}
				this.tFun = setTimeout(function() {
					that.countDown();
					//查询订单支付状态
					that.postOrderPayState();
				}, 1000)
			},
			//查询订单支付状态
			postOrderPayState(){
				var that = this;
				this.$http.post(this.PublicJs.publicPath + "/api/student.Order/ispay.html", {
					token:window.sessionStorage.getItem('token'),
					ids:this.$route.query.order_id
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						clearTimeout(this.tFun);
						this.itime = 120;
						this.error = false;
						this.$message({
							message: "付款成功，将为你自动跳转到学习中心",
							type: 'success',
							showClose: true
						});
						setTimeout(function(){
							that.$router.push({
								path: "/LearningCenter"
							});
						},3000);
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//点击刷新
			refurbishEvent(){
				this.loading = true;
				this.$refs.codeImg.innerHTML = ''
				clearTimeout(this.tFun);
				this.itime = 120;
				this.error = false;
				//生成二维码
				this.getSubmitOrder();
			},
			//获取购物车信息
			getCart(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Carts/index.html", {
					token:window.sessionStorage.getItem('token')
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						Bus.$emit('busCartNum', response.data.data.total);//购物车数量
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//查询订单详情
			getOrderDetail(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Order/detail.html", {
					token:window.sessionStorage.getItem('token'),
					ids:this.$route.query.order_id
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.orderno = response.data.data.ord_ordersn;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
		},
		created() {
			//获取购物车信息
			this.getCart();
			this.pay_type = this.PublicJs.Decrypt(this.$route.query.pay_type);
			this.totalPrice = this.PublicJs.Decrypt(this.$route.query.totalPrice);
			//生成二维码
			this.getSubmitOrder()
			console.log(this.$route.query.order_id)
			console.log(this.PublicJs.Decrypt('bm0Z4Tv6NJIGfpSd+vgkpw=='))
		},
		destroyed(){
			clearTimeout(this.tFun);
			this.itime = 120;
			this.error = false;
		}
	}
</script>

<style scoped="scoped">
	.payment{
		padding: 40px;
	}
	.main { width: 1200px; height: auto; margin: 0px auto; background-color: #FFF;}
	.main .h3 { height: 80px; line-height: 80px; font-size: 16px; color: #101e18; padding: 0 20px;}
	.main .h3 .left { width: auto; float: left;}
	.main .h3 .right { width: auto; display: block; width: auto; height: 80px; line-height: 80px;  float: right;}
	.main .h3 .right .Price {font-size: 24px; color: #ea3410;}
	.main .sweepCode { width: 100%; height: 588px; margin: 0px auto 45px auto; background: #FFFFFF;}
	.main .sweepCode .codeBox { width: 848px; height: 100%; margin: 0px auto; padding-top: 37px;}
	.main .sweepCode .codeBox .QRCode { width: 447px; height: 480px; float: left;}
	.main .sweepCode .codeBox .QRCode .title { width: 100%; height: 52px; line-height: 52px; text-align: center; font-size: 20px; color: #101e18;}
	.main .sweepCode .codeBox .QRCode .expiredReminder { width: 100%; height: 30px; line-height: 30px; font-size: 14px; color: #101E18; text-align: center;}
	.main .sweepCode .codeBox .QRCode .expiredReminder .Seconds { color: #ea3410;}
	.main .sweepCode .codeBox .QRCode .codeDiv { width: 100%; height: 360px;}
	.main .sweepCode .codeBox .QRCode .codeDiv .codeImgBxo { width: 297px; height: 297px; margin: 0px auto; position: relative;}
	.main .sweepCode .codeBox .QRCode .codeDiv .codeImgBxo .codeImg { width: 265px; height: 265px; border: 1px solid #e2e2e2; padding: 15px 15px;}
	.main .sweepCode .codeBox .QRCode .codeDiv .codeImgBxo .codeImg img {width: 100%; height: 100%;}
	.main .sweepCode .codeBox .QRCode .codeDiv .codeImgBxo .error { width: 297px; height: 297px; line-height: 297px; font-size: 20px; color: #101e18; text-align: center; position: absolute; left: 0px; top: 0px; background-color: rgb(255,255,255,0.7);}
	.main .sweepCode .codeBox .QRCode .codeDiv .refurbish { width: 100%; height: 20px; line-height: 20px; margin-top: 25px;  color: #2B54A9; text-align: center; cursor: pointer;}
	.main .sweepCode .codeBox .QRCode .codeDiv .refurbish span { font-size: 14px;}
	.main .sweepCode .codeBox .handset { width: 400px; height: 463px; float: left; background-size: 100% 100%; }
	.main .sweepCode .codeBox #handset{
		background-image: url(../images/iphoneWeChat.png);
	}
	.main .sweepCode .codeBox #zfbhandset{
		background-image: url(../images/iPhonePay.png);
	}
	
	.PaymentLayer.layui-layer-page .layui-layer-content{
		background-color: #edf2f7;
	}
</style>
